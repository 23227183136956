import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import Swal from "sweetalert2";

import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
// import Pagination from '../Layout/Pagination';
import { useNavigate, useLocation } from "react-router-dom";
import * as QuizApi from '../../api/quiz';
import {  post, putAPI } from "../../services/api.services";




function Winner() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id'); //console.log(id);  
    const quizName = queryString.get('quizName'); //console.log(id);  
    const[qqID,setqqID] = useState(0);
    const[quiz,setQuiz] = useState({});
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [errors, setErrors] = useState({});    
    const [weekLastDayStartTime, setEeekLastDayStartTime] = useState(0)
    const [weekLastDayEndTime, setEeekLastDayEndTime] = useState(0)
    
    useEffect(() => {
        document.title = "Quiz winners - QIM - Red FM";
    },);

    const getQuestionSetList = async() =>{ //Question selected for the quiz list
        setErrors();
        let res = await QuizApi.getQuestionSetList({id:id, page:0, perPage: 50});
        if(res.status === 1){
            setSelectedQuestions(res.payload);
            res.payload.forEach(element => {
                if(element.livedOn > 0 && element.closedOn == 0){
                    setqqID(element.qqID)
                }
            });
        } else{
            setSelectedQuestions([]);
            setErrors({...errors,['records']:"No record found"});
        }        
    }
    const getQuizDetails = async(ID) =>{
        setErrors();
        let res = await QuizApi.getDetails({ID});
        if(res.status === 1){
            setQuiz(res.payload)
            if(res.weekDetails?.ID){
                setEeekLastDayStartTime(res.weekDetails.startOn)
                setEeekLastDayEndTime(res.weekDetails.endOn)
            }
        } else{
            setQuiz({});
            setErrors({...errors,['records']:"No record found"});
        }        
    }
    useEffect(() => {
        getQuizDetails(id);
        getQuestionSetList();
    },[]);

   

    const regenerateWinner = async(quizID,qqID, userDetailID) =>{
        if(qqID!=0){
            let res = await post(`/quiz/set-question-winner`, {quizID,quizQuestionID:qqID, userDetailID})  
            if(res.status == 0 ){
                return Swal.fire({
                    text:res.message,
                    timer: 1500,
                })
            }else{
                //getQuizDetails(id);
                getQuestionSetList();
            }         
        }
    }

    const generateBumperWinner = async() => {
        let data = {};
        if(quiz.type == 1)
            data = {ID:id}
        else
            data = {weekID:quiz.weekID}
        await QuizApi.generateBumperWinner(data) 
        getQuizDetails(id);
        getQuestionSetList();
        // await QuizApi.generateBumperWinner({ID:id}) 
        // getQuizDetails(id);
        // getQuestionSetList();
    }

    const generateWinnerOftheDay = async() => {
        await QuizApi.generateWinnerOftheDay({ID:id}) 
        getQuizDetails(id);
        getQuestionSetList();
    }
    
        
   


    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper" >
                <div className="main-content">
                    <div className="page-content" style={{position:"fixed",top:0,bottom:0,left:0, right:0,background:"#fff",zIndex:1059,padding:"30px 20px"}}>
                        <div className="container-fluid">
                            <div className="row" >
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h3>
                                        <div className="logo-sm" style={{background:"#180f3d", display:"inline-block",verticalAlign:"top",padding:"10px",borderRadius:"10px"}}>
                                            <img src="/assets/images/logo-big.png" alt="" style={{height: "60px" }} />
                                        </div>
                                            
                                        <div style={{color:"orange",textTransform:"uppercase",display:"inline-block",fontWeight:"bold",verticalAlign:"top",padding:"10px 0 0 10px"}}>
                                       <span style={{color:"#180f3d"}}> QIM<span style={{fontSize:"11px"}}>Winners</span></span> <br/>
                                        {quizName} - Players & Winners
                                        </div> 
                                        </h3>
                                        
                                        <div style={{width:"40%"}} >
                                            
                                            <h6>Schedule: {quiz.startDate}</h6>
                                            {quiz.liveStatus == 0?(
                                                <>
                                                    {/* <button className='btn btn-sm btn-danger' style={{width:"60px"}} onClick={(e)=>startQuiz(id)}><i className="mdi mdi-play" style={{fontSize:"20px",}} ></i><br/>START</button> */}
                                                </>
                                            ):(
                                                <>
                                                   {quiz.liveStatus == 1?(
                                                <>

                                                    {/* <button className='btn btn-sm btn-warning' style={{width:"60px"}} onClick={(e)=>stopQuiz(e)}><i className="mdi mdi-stop-circle-outline" style={{fontSize:"20px",}} ></i><br/>FINISH</button> */}
                                                
                                                    <h6 style={{display:"inline-block", verticalAlign:"top"}}> 
                                                    &nbsp;  &nbsp; Started: {quiz.startedDate} 
                                                    {/* <div style={{paddingTop:"5px",color:"red"}}> &nbsp;  &nbsp; <i style={{fontSize:"24px"}} className='mdi mdi-clock-alert'></i> <Countdown date={Date.now() + quiz.remainingTime*1000} renderer={(e)=>quizCountDown(e)}/></div> */}
                                                    </h6>
                                                        </>
                                                    ):(
                                                        <>
                                                            {(quiz.liveStatus == 2 ||quiz.liveStatus == 3  ||quiz.liveStatus == 4) &&(
                                                                <>
                                                                <h6 style={{color:"green"}}> 
                                                                    Started: &nbsp; &nbsp;{quiz.startedDate} 
                                                                </h6>
                                                                <h6 style={{color:"#426cf5"}}> 
                                                                    Stopped: &nbsp;{quiz.stoppedDate} 
                                                                </h6>

                                                                    
                                                                </>
                                                    )
                                                    

                                                    }
                                                        </>
                                                    )

                                                    }
                                                </>
                                            )

                                            }

                                            
                                            {quiz.liveStatus == 3&&(
                                                <div style={{float:"right",width:"40%",marginTop:"-75px",color:"red"}}>
                                                     Winner Announced
                                                     <br/>
                                                     {quiz.winnerDate}
                                                    {/* <button className='btn btn-md btn-danger' onClick={(e)=>closeQuiz(e)}>Close Quiz</button> */}
                                                </div>
                                                )
                                            }
                                            {quiz.liveStatus == 4&&(
                                                <div style={{float:"right",width:"40%",marginTop:"-75px",color:"red"}}>
                                                     <h6>Winner Announced:</h6>
                                                     <h6>{quiz.winnerDate}</h6>
                                                     <h6 style={{color:"red"}}>Closed: {quiz.closedDate}</h6>
                                                </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* <!-- end page title --> */}
                            
                            <div className="row mt-0" style={{fontSize:"15px",marginTop:"-60px"}}>
                                                
                                <div  className='col-md-12'>    
                                    <div style={{border:"2px solid #cccc",height:"500px",overflow:"auto"}}>
                                        {/* <div style={{background:"#eee",padding:"4px"}}>
                                            <div style={{display:"inline-block",width:"82%",verticalAlign:"top"}}> &nbsp; &nbsp;
                                            <strong>Question: Total:{selectedQuestions?.length} &nbsp; &nbsp;
                                            Completed: 2 &nbsp; &nbsp; Live: 1 &nbsp;  &nbsp; Remaining: 12
                                            </strong>
                                            
                                            </div>
                                            <div style={{display:"inline-block",width:"16%",verticalAlign:"top"}}><b>Action</b></div>
                                        </div> */}

                                        {
                                            // need more condition for type=3 && week is completed
                                            (quiz.type==1 || quiz.type==3) &&
                                            <div style={{margin:"10px",padding:"10px 10px 0 10px", border:"1px solid #ccc"}}> 
                                                <h5 style={{display:"inline-block", width:"100px",background:"#336699", color:"#fff", textAlign:"center", padding:"10px"}}>
                                                    Bumper Prize <br/> Winner
                                                </h5>

                                                <div style={{display:"inline-block",padding:"10px", verticalAlign:"top"}}>
                                                {(quiz.bumperPrizeWinner && quiz.bumperPrizeWinner.fullName)?(
                                                    <>
                                                    <b>Name:</b> {quiz.bumperPrizeWinner?.fullName} &nbsp; &nbsp; &nbsp;
                                                    <b>City:</b> {quiz.bumperPrizeWinner?.city} &nbsp; &nbsp; &nbsp;
                                                    <b>Phone:</b> {quiz.bumperPrizeWinner?.phone} &nbsp; &nbsp; &nbsp;
                                                    <b>Email:</b> {quiz.bumperPrizeWinner?.email}
                                                    <br/>
                                                    <b>Question:</b> <div style={{display:"inline-block"}}  dangerouslySetInnerHTML={{__html: quiz.bumperPrizeWinner.question}}></div>
                                                    <div style={{marginTop:"-15px"}}  >
                                                    <b>Answer:</b> {quiz.bumperPrizeWinner.correctOption}
                                                    </div>
                                                    </>
                                                ) :(<><br/>
                                                Not  Generated
                                                </>)
                                                }
                                                </div>

                                                {
                                                    (quiz.liveStatus == 2 && ( (quiz.type == 3 && quiz.startOn > weekLastDayStartTime && quiz.startOn < weekLastDayEndTime) || quiz.type ==2 ) ) && (
                                                    <>  
                                                    <button className='btn btn-md btn-warning' style={{float:"right",marginRight:"0px",marginTop:"10px"}}  onClick={()=>generateBumperWinner(quiz)}>{quiz.isBumperPrizeWinnerGenerated? "Regenerate":"Generate"} Bumper <br/> Prize Winner</button>
                                                    </>)
                                                }

                                            </div>
                                        }

{
                                             quiz.type == 3 && 
                                             <div style={{margin:"10px",padding:"10px 10px 0 10px", border:"1px solid #ccc"}}> 
                                                 <h5 style={{display:"inline-block", width:"100px",background:"#336699", color:"#fff", textAlign:"center", padding:"10px"}}>
                                                     Winner <br/> Of the Day
                                                 </h5>
 
                                                 <div style={{display:"inline-block",padding:"10px", verticalAlign:"top"}}>
                                                 {(quiz.winnerOftheDay && quiz.winnerOftheDay.fullName)?(
                                                     <>
                                                     <b>Name:</b> {quiz.winnerOftheDay?.fullName} &nbsp; &nbsp; &nbsp;
                                                     <b>City:</b> {quiz.winnerOftheDay?.city} &nbsp; &nbsp; &nbsp;
                                                     <b>Phone:</b> {quiz.winnerOftheDay?.phone} &nbsp; &nbsp; &nbsp;
                                                     <b>Email:</b> {quiz.winnerOftheDay?.email}
                                                     <br/>
                                                     <b>Question:</b> <div style={{display:"inline-block"}}  dangerouslySetInnerHTML={{__html: quiz.winnerOftheDay.question}}></div>
                                                     <div style={{marginTop:"-15px"}}  >
                                                     <b>Answer:</b> {quiz.winnerOftheDay.correctOption}
                                                     </div>
                                                     </>
                                                 ) :(<><br/>
                                                 Not  Generated
                                                 </>)
                                                 }
                                                 </div>
                                                 {quiz.liveStatus == 2&&(<>  
                                                     <button className='btn btn-md btn-warning' style={{float:"right",marginRight:"0px",marginTop:"10px"}} onClick={(e)=>generateWinnerOftheDay(e)}>{quiz.isWinnerOftheDayGenerated? "Regenerate":"Generate"} <br/>Winner</button>
                                                 </>)}
 
                                             </div>
                                        }

                                        <table className="table table-striped1 table-hover1">                                                        
                                            <tbody>
                                            {selectedQuestions ? (<>
                                                {selectedQuestions?.length > 0 ? (<>
                                                    {selectedQuestions?.map((row, index) => {
                                                        return(<>
                                                        <tr style={{opacity:(row.closedOn == 0)?"1":".8"}} >
                                                            <td style={{textAlign:"left",padding:"30px 21px"}}>
                                                                <div style={{display:"inline-block",width:"62%",verticalAlign:"top"}} >
                                                                    <div  dangerouslySetInnerHTML={{__html: row?.question}}></div>
                                                                    <div>
                                                                        {row.options.length > 0 ? (
                                                                            <>
                                                                            {row.options.map( (item, index) => {
                                                                                return(<span style={{color:(item.option == row.correctOption)?"green":"",fontWeight:(item.option == row.correctOption)?"bold":""}}>{String.fromCharCode(index++ + 97)}) {item.option} &nbsp;  &nbsp;  &nbsp; &nbsp; </span>)
                                                                            })
                                                                            }
                                                                            </>
                                                                        ):(<></>)
                                                                        }
                                                                        {(row.explanation!="" && row.explanation != null) &&(<>
                                                                            <br/><br/>
                                                                            <span style={{fontSize:"14px",fontStyle:'italic'}}>Explantion: {row.explanation}</span>
                                                                        </>
                                                                           
                                                                        )
                                                                        }
                                                                    </div>                                                                    
                                                                </div>
                                                                <div style={{display:'inline-block',width:"26%",fontSize:"13px",borderLeft:"4px solid green",paddingLeft:"5px"}}>
                                                                {(row.winner.fullName)?(
                                                                    <>
                                                                    <h6><strong style={{borderBottom:"3px solid #3471eb"}}>Winner</strong></h6>
                                                                        Name: <b>{row.winner.fullName}</b><br/>
                                                                        City: <b>{row.winner.city}</b><br/>
                                                                        Phone: <b>{row.winner.phone}</b><br/>
                                                                        Email: <b>{row.winner.email}</b>
                                                                    </>
                                                                        
                                                                    
                                                                    ):(<>
                                                                    {
                                                                        row.closedOn > 0 &&(
                                                                            <>No winner found!</>
                                                                        )
                                                                    }
                                                                    </>)
                                                                }
                                                                </div>
                                                               
                                                                <div style={{display:"inline-block",width:"10%",verticalAlign:"top"}}>
                                                                    {
                                                                    (((quiz.type==1 && quiz.liveStatus == 1) || quiz.liveStatus == 2) && quiz.type != 3) &&
                                                                    <button className='btn btn-md btn-primary' style={{width:"140px",padding:"10px 0", marginBottom:"10px"}} onClick={(e)=>regenerateWinner(quiz.ID,row.qqID, row.winner?.userDetailID)}>{row.winner.fullName && 'RE'}GENERATE WINNER</button>
                                                                    }

                                                                    {row.livedOn == 0 ?(
                                                                        <>
                                                                         {(quiz.liveStatus == 1 && quiz.type ==1) &&(
                                                                            //  <button className='btn btn-md btn-danger' style={{width:"140px"}}  onClick={(e)=>startQuestion(row.qqID, id)}><i className="mdi mdi-play" style={{fontSize:"20px",}} ></i><br/>MAKE LIVE</button>
                                                                            <span className='btn btn-danger btn-xs'>Not Conducted</span>
                                                                         )
                                                                        }    
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                            {row.closedOn > 0 ?(
                                                                            <>
                                                                             {quiz.liveStatus != 0 &&(
                                                                                <span disabled className='btn btn-md btn-success' style={{width:"140px"}}><i className="mdi mdi-checkbox-marked-circle-outline" style={{fontSize:"20px",}} ></i> COMPLETED</span>
                                                                             )}
                                                                                </>
                                                                            ):(
                                                                                <>
                                                                                {quiz.liveStatus == 1 &&(<>
                                                                                {/* <Countdown date={Date.now() + 10000} /> */}
                                                                                {/* <Countdown date={Date.now() + row.remainingTime*1000} renderer={(e)=>questionCountDown(e)}/> */}
                                                                                {/* <button className='btn btn-md btn-warning' style={{width:"140px"}} onClick={(e)=>stopQuestion(row.qqID, id)}><i className="mdi mdi-stop-circle-outline" style={{fontSize:"20px",}} ></i><br/>STOP</button> */}
                                                                                </>)}
                                                                                </>
                                                                            )
                                                                            }
                                                                        </>
                                                                    )

                                                                    }
                                                                </div>
                                                                
                                                            </td>
                                                        </tr>
                                                        </>)
                                                    })}
                                                </>) : (<>
                                                    <tr>
                                                        <td colSpan={6}>No question found</td>
                                                    </tr>
                                                </>)}
                                            </>) : (<>
                                            
                                            </>)}
                                                
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>
                                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Winner