import React from 'react';
import { useEffect, useState } from "react";
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import { get } from "../services/api.services";
import LineChart from './Chart/Line';
import BarChart from './Chart/Bar';
import { DateFunc } from '../Common/Date';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Dashboard() {

    // Get the current date
    const currentDate = new Date();
    
    // Subtract 14 days (2 weeks) from the current date
    const twoWeeksBackDate = new Date(currentDate);
    twoWeeksBackDate.setDate(currentDate.getDate() - 14);

    const [startDate, setStartDate] = useState(twoWeeksBackDate);
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        console.log(dates)
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
    
    const [totalRecords, setTotalRecords] = useState([]);
    const [registrationData, setRegistrationData] = useState([]);
    const [recentCityData,setRecentCityData] = useState([]);
    const [topCities, setTopCities] = useState([]);
    const [topQuizes, setTopQuizes] = useState([]);
    const [topQuestions, setTopQuestions] = useState([]);
    const [topUsers, setTopUsers] = useState([]);

    useEffect(() => {
        document.title = "Dashboard - QIM redFM"
    },);

    const getTotalData = async() => {    
        let formatedStartDt ="";   
        let formatedEndDt ="";   
        if(startDate != "" && startDate != null){
            //const date = new Date();
            formatedStartDt = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
        } 
        if(endDate != "" && endDate != null){
            //const date = new Date();
            formatedEndDt = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
        }
        let res = await get('/dashboard/total-data', {startDate: formatedStartDt, endDate: formatedEndDt});
        setTotalRecords(res?.payload)
    }
    const getDayWiseRegistrationData = async() => {    
        let formatedStartDt ="";   
        let formatedEndDt ="";   
        if(startDate != "" && startDate != null){
            //const date = new Date();
            formatedStartDt = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
        } 
        if(endDate != "" && endDate != null){
            //const date = new Date();
            formatedEndDt = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
        }  
        let res = await get('/dashboard/daywise-registration', {startDate: formatedStartDt, endDate: formatedEndDt, 
                //days:DateFunc.last7Days()
            });
        setRegistrationData(res?.payload)
    }
    const getRecentCityData = async() => {    
        let res = await get('/dashboard/citywise-registration', {noOfdays:7});
        let cities = [];
        let users = [];
        for(let i = 0; i < res?.payload.length; i++){
            cities[i]= res?.payload[i].city;
            users[i]= res?.payload[i].totalUser;
        }
        setRecentCityData({cities,users})
    }
    
    const getTopQuiz = async() => {    
        let res = await get('/dashboard/top-quizes', {page:1,perPage:25,quizType:'all'});//all,live,regular
        setTopQuizes(res?.payload)
    }    
    const getTopQuestion = async() => {    
        let res = await get('/dashboard/top-questions', {page:1,perPage:25, questionType:'all'});//all,live,regular
        setTopQuestions(res?.payload)
    }
    const getTopCities = async() => {    
        let formatedStartDt ="";   
        let formatedEndDt ="";   
        if(startDate != "" && startDate != null){
            //const date = new Date();
            formatedStartDt = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
        } 
        if(endDate != "" && endDate != null){
            //const date = new Date();
            formatedEndDt = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
        }
        let res = await get('/dashboard/top-cities', {page:1,perPage:25, cityCtriteria:'users', startDate: formatedStartDt, endDate: formatedEndDt});//users, players
        setTopCities(res?.payload);
        let cities = [];
        let users = [];
        for(let i = 0; i < res?.payload.length; i++){
            cities[i]= res?.payload[i].city;
            users[i]= res?.payload[i].totalUsers;
        }
        setRecentCityData({cities,users})

    }
    const getTopUsers = async() => {    
        let res = await get('/dashboard/top-users', {page:1,perPage:25,userCriteria:'attempts'});//attempts,correct answers
        setTopUsers(res?.payload)
    }
    useEffect(() => {
        getTotalData();
        getDayWiseRegistrationData();
        //getRecentCityData();
    },[]);

    useEffect(() => {
        getTopCities();
    },[]);
    // useEffect(() => {
    //     getTopQuiz();
    // },[]);
    // useEffect(() => {
    //     getTopQuestion();
    // },[]);
    // useEffect(() => {
    //     getTopUsers();
    // },[]);

    let chartInfo = {
        labels: DateFunc.listDaysBetween(startDate, endDate),
        data: registrationData
    }

    let BarInfo = {
        
        labels: recentCityData.cities,
        data: recentCityData.users
    }
    
    const refreshData = ()=>{
        getTotalData();
        getDayWiseRegistrationData();
        getTopCities();
    }
    
    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">                            

                        <div className="row mb-2 mt-3">
                            <div className='col-md-2' style={{paddingRight:0}}>Select Date Range</div>
                            <div className='col-md-3'>

                            <DatePicker 
                                 className=''
                                 key = "registrationDate"
                                 id = "registrationDate"
                                placeholder="Enter start time"
                                dateFormat="d-MM-yyyy"
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                autoComplete = "off"
                            />
                            </div>
                            <div className="col-md-2">
                            <button className='btn btn-sm btn-info' onClick={(e)=>refreshData(e)}>Go</button>
                            </div>
                        </div>
                        
                                                    
                            {/********** BOXES START *********/}
                           <div className="row mb-2 mt-3">

                            
                           <div className='col-md-12 mb-3 mt-30'>
                                    <h5>
                                {/* {
                                    (startDate =="" || endDate =="")?(
                                        <>
                                            Showing records of last 30 days
                                        </>
                                    ):(
                                        <>
                                            
                                        </>
                                    )
                                } */}
                                </h5>
                                </div>
                                    {totalRecords?.map((rec) => (
                                <div key={"box-1"} className="col-6 col-lg-4 stylishCard">
                                        <div className="card" style={{minHeight:"120px"}}>
                                            <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                                                <span className="analyticVal">{rec.value}</span>
                                                <span className="analyticLbl">
                                                {rec.label}{" "}
                                                </span>
                                                <span className="analyticST">{rec.detail}</span>
                                            </div>
                                </div>
                            </div>
                                    ))}
                                </div>
                            
                            {/******** CHART STARTS *********/}
                            <div className="row mt-0">
                                <div className="col-md-12">
                                    <div  style={{background:"#fff"}}>
                                        <LineChart chartInfo={chartInfo} />                                    
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">                               
                                <div className="col-md-12 mt-3">
                                    <div  style={{background:"#fff"}}>
                                        <BarChart BarInfo={BarInfo} />                                    
                                    </div>
                                </div>
                            </div>

                            

                            {/* ***CHART ENDS */}

                            {/* TABULAR REPORTS */}
                            <div className="row mt-3">
                            {/* <div className="col-md-6 mt-3">
                                    <div  style={{background:"#fff",height:"300px",padding:"19px"}}>
                                        <h6><b>Top Cities</b></h6> 
                                        <div style={{height:"250px",overflow:"auto"}}>
                                        <table className='table'>
                                            <tr>
                                                <th>City</th>
                                                <th>Registrations</th>
                                                <th>Participants</th>
                                            </tr>
                                            {
                                                topCities.map(c=>{
                                                    return (
                                                    <tr>  
                                                        <td>{c.city}</td>
                                                        <td>{c.totalUsers}</td>
                                                        <td>{c.totalPlayers}</td>
                                                    </tr>
                                                    )
                                                    
                                                })
                                            }       
                                        </table> 
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-6 mt-3">
                                    <div  style={{background:"#fff",height:"300px",padding:"19px"}}>
                                        <h6><b>Top Users </b></h6>  
                                        <div style={{height:"250px",overflow:"auto"}}>

                                        <table className='table'>
                                            <tr>
                                                <th>Nane/City</th>
                                                <th>Phone/Email</th>
                                                <th>Total Response</th>
                                                <th>Total Torrect</th>
                                            </tr>
                                        {
                                            topUsers.map(u=>{
                                               return(
                                                <tr>
                                                    <td>{u.fullName}<br/>{u.city}</td>
                                                    <td>{u.phone}<br/>{u.email}</td>
                                                    <td>{u.totalResponses}</td>
                                                    <td>{u.totalCorrect}</td>
                                                </tr>
                                               )
                                                
                                                
                                            })
                                        }                                
                                        </table> 
                                    </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-6 mt-3">
                                    <div  style={{background:"#fff",height:"300px",padding:"19px"}}>
                                        <h6><b>Top Quizes</b></h6> 
                                        <div style={{height:"250px",overflow:"auto"}}>

                                        <table className='table'>
                                            <tr>
                                                <th>Title</th>
                                                <th>Total Responses</th>
                                                <th>Total Participants</th>
                                            </tr>
                                            {
                                                topQuizes.map(quiz=>{
                                                    return(
                                                    <tr>  
                                                        <td>{quiz.title}</td>
                                                        <td>{quiz.totalResponses}</td>
                                                        <td>{quiz.totalParticipants}</td>
                                                    </tr>
                                                    )
                                                    
                                                })
                                            }                                
                                        </table> 
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div  style={{background:"#fff",height:"300px",padding:"19px"}}>
                                        <h6><b>Top Questions</b></h6>  
                                        <div style={{height:"250px",overflow:"auto"}}>

                                        <table className='table'>
                                            <tr>
                                                <th>Question</th>
                                                <th>Answer</th>
                                                <th>Participants</th>
                                            </tr>
                                            {
                                                topQuestions.map(q=>{
                                                    return(
                                                    <tr>  
                                                        <td dangerouslySetInnerHTML={{__html:q.question}}></td>
                                                        <td>{q.correctOption}</td>
                                                        <td>{q.totalParticipants}</td>
                                                    </tr>
                                                    )
                                                    
                                                })
                                            }                                
                                        </table> 
                                    </div>
                                    </div>
                                </div> */}
                               
                                
                            </div>
                            <br/><br/>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer /> 
        </div>
    )
}

export default Dashboard;