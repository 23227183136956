import React, { useState, useEffect } from 'react';
//import { defaultPage } from '../../Common/Constant';
import {DateFunc} from '../../Common/Date';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { useNavigate } from "react-router-dom";
import * as QuizApi from '../../api/quiz';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from 'react-tooltip';

function Quiz() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Quiz List - QIM - Red FM";
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [weeks, setWeeks] = useState();
    const [week, setWeek] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [startDate, setStartDate] = useState(new Date());//datepicker start date
    const [showForm, setShowForm] = useState(false);

    const getList = async() =>{
        setErrors();
        let res = await QuizApi.getList({page:0, perPage: 50, searchKeyword: searchKeyword});
        if(res.status === 1){
            setLists(res.payload)
            setPageInfo(res.paginationInfo)
        } else{
            setLists([]);
            setErrors({...errors,['records']:"No record found"});
        }        
    }

    const getWeeks = async() =>{
        setErrors();
        let res = await QuizApi.getWeeks();
        if(res.status === 1){
            setWeeks(res.payload)
        } else{
            setWeeks([]);
            setErrors({...errors,['weeks']:"No weeks found"});
        }        
    }

    useEffect(() => {
        getList();
        getWeeks();
    },[]);

    
    const updateOrSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await QuizApi.updateStatus({id:id,status:status});
        if(res && res.status === 1){
            getList();            
        }
    }

    const trashRecord = async (id) => {
        let res = await QuizApi.trash({ID:id})
        if(res && res.status === 1){
            getList()
        }
    }

    const changePage = (pageInfo) => {
        document.getElementById('quizLink').value = "";        
        document.getElementById('quizText').value = "";   
        document.getElementById('quizLink').disabled = false;
        setPageInfo(pageInfo);
    }

/* ADD MENU SECTION */

    const setFormValues=(data)=>{
        let copy = {
            ID:data.ID,
            type: data.type,
            title: data.title,
            subTitle: data.subTitle,
            description: data.description,
            duration: data.duration,
            season: data.season,
            weekID: data.weekID,
            autoStart: data.autoStart,
            resultWaitTime: data.resultWaitTime,
        }
        setForm(copy)
        let date = new Date(data.startOn*1000);
        setStartDate(new Date(date.toString()))
        if(document.getElementById('autoStart'))
        document.getElementById('autoStart').checked = data?.autoStart;
        setShowForm(true);
    }
       
    const validateForm = () =>{
        const {name} = form;
        const newErrors = {};
        if(!name || name === '') newErrors.name = 'Please enter this field';
        return newErrors;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors();
        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
            if(key == 'unit'){
                if(formInput['unit'] == 'minute')
                    formInput['duration'] = formInput['duration']*60;
                else
                    formInput['duration'] = formInput['duration']*60*60;
            }
        }
        // const formErrors = validateForm();
        // if(Object.keys(formErrors).length > 0){
        //     setErrors(formErrors)
        // } else {
            let id= form.ID;
            let res = {};
            if(id && id != null && id!= '' && id != 0){
                formInput.ID = id; //console.log(formInput)
                res = await QuizApi.update(formInput);
            }else{
                res = await QuizApi.create(formInput);
            }
            if(res.status === 1){                
                getList();
                setForm({});
                document.getElementById('quizForm').reset();    


            }else {
                setErrors({...errors,['submit']:res.message})
            }
       // }
        
    }

    const handleCancel = () => {   
        document.getElementById('quizForm').reset();    
        setForm({});
    }

   
const toggleForm = async(e) => {
    if(showForm === true){
        setShowForm(false);
            e.target.innerHTML = "Add Quiz +";
    }else{
        setShowForm(true);
        e.target.innerHTML = "Hide Form -";
    }
}
        
    const handleWeek = async(date) => {
        let time = Math.floor(new Date(date).getTime() / 1000);
        weeks.forEach(week => {            
            if (time >= week.startOnIST && time <= week.endOnIST){
                setWeek(week);
            }
        });

        
    }       

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Manage Quiz</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Quiz</li>
                                                <li className="breadcrumb-item active">Manage Quiz</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                            {/* <!-- end page title --> */}
                            <div className="row mt-0">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body" style={{minHeight:"45px"}}>
                                            <button style={{position:"absolute",right:"10px",top:"5px"}} className='btn btn-danger' onClick={(e)=>toggleForm(e)}>Add Quiz + </button>

                                            <div className='row1'>
                                                {
                                                    showForm && (
                                                        <>
                                                <form onSubmit={handleSubmit} id="quizForm">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3 required_field" required="1">{errors?.submit}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        
                                                                    
                                                                    <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Title 
                                                                    <span className="required_field">*{errors?.title}</span>
                                                                </label>
                                                                <input type="text" key = "title" className="form-control" placeholder="Enter quiz title" id="title" name='title' defaultValue={form?.title?form.title:""} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Sub Title 
                                                                    <span className="required_field">*{errors?.subTitle}</span>
                                                                </label>
                                                                <input type="text" key = "subTitle" className="form-control" placeholder="Enter quiz sub title" id="subTitle" name="subTitle" defaultValue={form?.subTitle?form.subTitle:""} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Description 
                                                                    <span className="required_field">*{errors?.description}</span>
                                                                </label>
                                                                <input type="text" key = "description" className="form-control" placeholder="Enter quiz description" id="description" name="description" defaultValue={form?.description?form.description:""}  />
                                                            </div>
                                                        </div>
                                                                    <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Type 
                                                                    <span className="required_field">*{errors?.type}</span>
                                                                </label>
                                                                            <select type="text" key = "type" className="form-control" id="type" name="type"  >
                                                                                <option value="" >Select</option>
                                                                                <option value="1" selected={form.type==1}  >Play Along</option>
                                                                                <option value="3" selected={form.type==3} >Auto Quiz</option>
                                                                                <option value="2" selected={form.type==2} >Regular</option>
                                                                </select>
                                                            </div>
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <div className="mb-3">
                                                                            <label className="form-label mb-0">Season 
                                                                                <span className="required_field">*{errors?.season}</span>
                                                                            </label>
                                                                            <input type="number" readOnly  key = "season" className="form-control" id="season" name="season" defaultValue={3} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                            <div className="mb-3 customDatePickerWidth">
                                                                <label className="form-label mb-0">Start On 
                                                                    <span className="required_field">*{errors?.startOn}</span>
                                                                </label>
                                                                {/* <input type="text" key = "startOn" className="form-control" placeholder="Enter start time" id="startOn" name ="startOn" defaultValue={form?.startOn?form.startOn:""}  /> */}
                                                                <DatePicker 
                                                                    className='form-control'
                                                                    key = "startOn"
                                                                    placeholder="Enter start time"
                                                                    id="startOn"
                                                                    name ="startOn" 
                                                                    selected={startDate} 
                                                                                onChange={(date) => {setStartDate(date);handleWeek(date);}}
                                                                    dateFormat="d-MM-yyyy h:mm aa"
                                                                    timeIntervals={5}
                                                                    showTimeSelect
                                                                 />
                                                            </div>
                                                        </div>
                                                                    <div className="col-md-1">
                                                                        <div className="mb-3">
                                                                            <label className="form-label mb-0">Week 
                                                                                <button data-tooltip-id="my-tooltip" className='btn mx-2' style={{color:"blue",padding:" 0 5px"}}> ? </button>
                                                                                <Tooltip id="my-tooltip" place="top" content={
                                                                                    weeks.map(w=>
                                                                                        <div> {w.name} : {w.startDate} - {w.endDate} </div>
                                                                                    )
                                                                                } />
                                                                                <span className="required_field">*{errors?.weeks}</span>
                                                                            </label>
                                                                            <select key = "weekID" className="form-control" id="weekID" name="weekID"  readOnly defaultValue={form?.weekID}  >
                                                                            <option>Select</option>
                                                                                {
                                                                                    weeks.map(w=>
                                                                                        <option value={w.ID} selected={(w.ID === week?.ID || w.ID === form.weekID)}> {w.name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                        <div className="col-md-2">
                                                                        <div className="mb-3" style={{display:'inline-block', width:"90%"}}>
                                                                            <label className="form-label mb-0">Duration
                                                                    <span className="required_field">*{errors?.duration}</span>
                                                                </label>
                                                                            <input type="text" key = "duration" className="form-control" placeholder="Quiz duration" id="duration" name="duration" defaultValue={form?.duration?form.duration:""}  />
                                                            </div>
                                                                        {/* <div className="mb-3" style={{display:'inline-block', width:"35%", marginLeft:"3px"}}>
                                                                            <label className="form-label mb-0">
                                                                                Unit <span className="required_field">{errors?.duration}</span>
                                                                            </label>
                                                                            <select key = "unit" className="form-control" id="unit" name="unit"  >
                                                                                <option value="" >Select</option>
                                                                                <option value="second"   >Second</option>
                                                                                <option value="minute" selected={true} >Minute</option>
                                                                                <option value="hour"  >Hour</option>
                                                                            </select>
                                                                        </div> */}
                                                        </div>
                                                                    <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Auto Start
                                                                    <span className="required_field">*{errors?.autoStart}</span>
                                                                </label><br/>
                                                                <input style={{marginTop:"10px",display:"inline-block",verticalAlign:"top"}} type="checkbox" key = "autoStart" id="autoStart" name='autoStart' value='1' defaultChecked={(form?.autoStart && form.autoStart === 1)? true:false} />
                                                                <div style={{fontSize:"11px",display:"inline-block",width:"90%",paddingLeft:"10px"}}>Shows quiz is ready when the scheduled time reached</div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Auto Play 
                                                                    <span className="required_field">*{errors?.autoPlay}</span>
                                                                </label><br/>
                                                                <input type="checkbox" key = "autoPlay"  id="autoPlay" name='autoPlay' value='1' defaultChecked={(form?.autoResult && form.autoResult == 1)? true:false}  />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Question Time Gap 
                                                                    <span className="required_field">*{errors?.questionGap}</span>
                                                                </label>
                                                                <input type="text" key = "questionGap" className="form-control" placeholder="Enter time gap between questions" id="questionGap" name='questionGap' defaultValue={form?.questionGap?form.questionGap:""}  />
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Result Wait Time
                                                                    <span className="required_field">*{errors?.resultWaitTime}</span>
                                                                </label>
                                                                <input type="text" key = "resultWaitTime" className="form-control" placeholder="Enter result wait time" id="resultWaitTime" name='resultWaitTime' defaultValue={form?.resultWaitTime?form.resultWaitTime:""}  /><span style={{fontSize:"11px"}}>In seconds</span>
                                                            </div>
                                                        </div> 
                                                                    {/* <div className="col-md-2">
                                                                        <div className="mb-3">
                                                                            {
                                                                                errors?.isQuizLevelWinner &&
                                                                                <label className="form-label mb-0">
                                                                                    <span className="required_field">*{errors?.isQuizLevelWinner}</span>
                                                                                </label>
                                                                            }
                                                                            <input style={{marginTop:"4px",display:"inline-block",verticalAlign:"top"}} type="checkbox" key = "autoStart" id="isQuizLevelWinner" name='isQuizLevelWinner' value='1' defaultChecked={(form?.isQuizLevelWinner && form.isQuizLevelWinner === 1)? true:false} />
                                                                            <div style={{fontSize:"11px",display:"inline-block",width:"90%",paddingLeft:"10px"}}>Quiz Level Winner</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-2">
                                                                        <div className="mb-3">
                                                                            {
                                                                                errors?.isBumperWinner &&
                                                                                <label className="form-label mb-0">
                                                                                    <span className="required_field">*{errors?.isBumperWinner}</span>
                                                                                </label>
                                                                            }
                                                                            <input style={{marginTop:"4px",display:"inline-block",verticalAlign:"top"}} type="checkbox" key = "autoStart" id="isBumperWinner" name='isBumperWinner' value='1' defaultChecked={(form?.isBumperWinner && form.isBumperWinner === 1)? true:false} />
                                                                            <div style={{fontSize:"11px",display:"inline-block",width:"90%",paddingLeft:"10px"}}>Bumper Winner</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-2">
                                                                        <div className="mb-3">
                                                                            {
                                                                                errors?.isQuestionWinner &&
                                                                                <label className="form-label mb-0">
                                                                                    <span className="required_field">*{errors?.isQuestionWinner}</span>
                                                                                </label>
                                                                            }
                                                                            <input style={{marginTop:"4px",display:"inline-block",verticalAlign:"top"}} type="checkbox" key = "autoStart" id="isQuestionWinner" name='isQuestionWinner' value='1' defaultChecked={(form?.isQuestionWinner && form.isQuestionWinner === 1)? true:false} />
                                                                            <div style={{fontSize:"11px",display:"inline-block",width:"90%",paddingLeft:"10px"}}>Question Level Winner</div>
                                                                        </div>
                                                                    </div> */}
                                                                    
                                                        {/* <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Auto Result 
                                                                    <span className="required_field">*{errors?.autoResult}</span>
                                                                </label><br/>
                                                                <input style={{marginTop:"10px"}} type="checkbox" key = "autoResult" id="autoResult" name='autoResult' value='1' defaultChecked={(form?.autoResult && form.autoResult == 1)? true:false}/>
                                                            </div>
                                                        </div> */}
                                                        
                                                        
                                                    {/* </div> */}
                                                    {/* <!--end row--> */}

                                                    {/* <div className="row text-end d-inline"> */}
                                                        <div className="col-md-12 mt-3" style={{textAlign:"right"}}>
                                                            <button type="submit" className="btn btn-primary mx-2" id="submitButton" >Submit</button>
                                                            <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-0">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            
                                            <table className="table table-striped1 table-hover1 table_client1">
                                                <thead>
                                                    <tr className="" style={{background:"#eee"}}>
                                                        <th scope="col" style={{textAlign:"left"}}>Title</th>
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Description</th>                 */}
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Type</th> */}
                                                        <th scope="col" style={{textAlign:"left"}}>Time Frames</th>
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Controls</th>  */}
                                                        <th scope="col" style={{textAlign:"left"}}>Question</th> 
                                                        <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                        <th scope="col" style={{width:"230px"}}> &nbsp; &nbsp; Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, i) => {
                                                            return(<>
                                                            <tr >
                                                                <td style={{textAlign:"left"}}>
                                                                    <span style={{textAlign:"left"}}>{row?.title}</span>
                                                                    <div style={{textAlign:"left","font-size":"11px"}}>
                                                                        <b>Sub Title:</b> {row?.subTitle}
                                                                    </div>
                                                                    <div style={{textAlign:"left","font-size":"11px"}}>
                                                                        <b>Desc:</b> {row?.description}
                                                                    </div>
                                                                    <div style={{textAlign:"left","font-size":"11px"}}>
                                                                    <b>Type:</b> <span style={{textAlign:"left"}}>{row?.type == 1?"Live":(row?.type == 3?"Auto Play":"Regular (Powerplay)")}</span>
                                                                    </div>
                                                                    <div style={{textAlign:"left","font-size":"11px"}}>
                                                                        <b>Auto Start: </b><span style={{textAlign:"left"}}>{row?.autoStart==1?"Yes":"No"}</span><br/>
                                                                    
                                                                    </div>

                                                                </td>
                                                                {/* <td style={{textAlign:"left"}}>
                                                                    <span style={{textAlign:"left"}}>{row?.description}</span>
                                                                </td> */}
                                                                {/* <td style={{textAlign:"left"}}>
                                                                    <span style={{textAlign:"left"}}>{row?.type == 1?"Live":(row?.type == 0?"Practice":"Regular (Powerplay)")}</span>
                                                                </td> */}
                                                                <td style={{textAlign:"left"}}>
                                                                    Schedule: <span style={{textAlign:"left"}}><b>{DateFunc.format(row?.startOn*1000,"dd mmm yyyy hh:mm ampm")}</b></span><br/>
                                                                    Duration: <span style={{textAlign:"left"}}>
                                                                        
                                                                        {/* {row?.duration} */}
                                                                        {
                                                                            Math.floor(row?.duration/(60*60)) > 0 && 
                                                                            (
                                                                                <b>{Math.floor(row?.duration/(60*60))} hr(s)</b>

                                                                            )                                                                            
                                                                        }
                                                                        {
                                                                            Math.floor((row?.duration%(60*60))/60) > 0 && 
                                                                            (
                                                                                <b> &nbsp; {Math.floor((row?.duration%(60*60))/60)} min(s)</b>

                                                                            )                                                                            
                                                                        }
                                                                        {
                                                                            row?.duration%60 > 0 && (
                                                                                <b>
                                                                                &nbsp; {row?.duration%60} sec(s)
                                                                                </b>
                                                                            )
                                                                        }
                                                                    
                                                                    </span>
                                                                    <br/>
                                                                    Result Wait: <span style={{textAlign:"left"}}>
                                                                        {
                                                                            Math.floor(row?.resultWaitTime/60) > 0 && 
                                                                            (
                                                                                <b>{Math.floor(row?.resultWaitTime/60)} min(s)</b>

                                                                            )                                                                            
                                                                        }
                                                                        {
                                                                            row?.resultWaitTime%60 > 0 && (
                                                                                <b>
                                                                                &nbsp; {row?.resultWaitTime%60} sec(s)
                                                                                </b>
                                                                            )
                                                                        }
                                                                        </span>
                                                                    {/* Started: <span style={{textAlign:"left"}}><b>{row?.startDate}</b></span><br/> */}
                                                                </td>
                                                                {/* <td style={{textAlign:"left"}}>
                                                                    Auto Start: <span style={{textAlign:"left"}}><b>{row?.autoStart==1?"Yes":"No"}</b></span><br/> */}
                                                                    {/* Question Gap: <span style={{textAlign:"left"}}>{row?.questionGap}</span><br/> */}
                                                                    {/* Auto Result: <span style={{textAlign:"left"}}>{row?.questionGap}</span><br/> */}
                                                                {/* </td> */}
                                                                <td style={{textAlign:"left"}}>
                                                                    <span style={{color:"#0080ff","text-decoration":"underline","font-weight":"bold","cursor":"pointer"}} onClick={(()=>navigate("/quiz/questions?id="+row.ID+"&quizName="+row.title))}>Questions</span>
                                                                </td>
                                                                
                                                                <td style={{textAlign:"left"}}>   
                                                                    <div className="form-check form-switch" style={{textAlign:'left'}}>
                                                                    {row.liveStatus == 0?(<>
                                                                        <input className="btn-lg form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.ID, row.status)} /> <span style={{fontSize:"11px"}}>{row.status ==1?"Locked":"Draft"}</span>
                                                                    </>
                                                                        ):(
                                                                            <>
                                                                            <button className='btn btn-sm' style={{color:"red"}}>LIVED</button>
                                                                            </>
                                                                        )
                                                                    }
                                                                        </div> 
                                                                    
                                                                    
                                                                   
                                                                </td>
                                                                <td style={{textAlign:"right"}}>
                                                                    {row.liveStatus == 0?(
                                                                        <button disabled={row.status==0?true:false}  onClick={(()=>window.open("/quiz/play?id="+row.ID+"&quizName="+row.title, "_blank", "noreferrer"))} target='_blank' className="btn btn-sm btn-danger"  style={{'font-size':"14px"}}>
                                                                        <i className="mdi mdi-play" ></i> Make Live 
                                                                        </button> 
                                                                         ):(
                                                                        <button  onClick={(()=>window.open("/quiz/play?id="+row.ID+"&quizName="+row.title, "_blank", "noreferrer"))} target='_blank' className="btn btn-sm btn-danger"  style={{'font-size':"14px"}}>
                                                                        <i className="mdi mdi-play" ></i> Play Details 
                                                                    </button>
                                                                    )                                                                        
                                                                    }
                                                                    <div className="dropdown align-items-right" style={{display:"inline-block"}}>
                                                                        <div className="dropdown ms-sm-3 ">
                                                                            <button type="button" className="btn btn-sm " id="page-header-user-dropdown1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"13px", background:"#eee"}}>
                                                                            More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{setFormValues(row);
                                                                                //Util.scrollToTop()
                                                                                }} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                                <div className="dropdown-divider"></div>
                                                                                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>trashRecord(row.ID)} ><i className="mdi mdi-delete"></i> Delete</a>                                                                            
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5} id="noRecordMsg"> <span className="required_field">*{errors?.records?errors.records:"Loading quizs..."}</span></td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Quiz